import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavExtrasService {
  extras: any;
  constructor() { }

  public setExtras(data: any) {
    if (!this.extras) {
      this.extras = {};
    }
    Object.keys(data).forEach(key => {
      this.extras[key] = data[key];
    });
  }

  public getExtras(key: string) {
    if (!this.extras) {
      this.extras = {};
    }
    return this.extras[key];
  }
}
