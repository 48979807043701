import { Injectable } from '@angular/core';

import { Storage } from '@ionic/storage';

import { UtilsService } from '../utils/utils.service';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor(
    private storage: Storage
  ) {

  }

  getResource(key: string) {
    return this.storage.get(key);
  }

  setResource(key: string, value: any) {
    if (UtilsService.hasLength(key)) {
      return this.storage.set(key, value);
    }
    return Promise.resolve();
  }
}
