import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { ConfigurationService } from '../configuration/configuration.service';

@Injectable({
  providedIn: 'root'
})
export class IdentificationService {

  constructor(
    private storage: Storage
  ) {
  }

  setApiKey(apiKey: string): Promise<string> {
    return this.storage.set(ConfigurationService.config.apiKeyID, apiKey);
  }

  getApiKey(): any {
    return this.storage.get(ConfigurationService.config.apiKeyID);
  }

  deleteApiKey(): void {
    this.storage.remove(ConfigurationService.config.apiKeyID);
  }
}
