import { Injectable } from '@angular/core';

import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ObservablesService {

  consultedTasksSubject = new Subject<boolean>();
  popoverSubject = new Subject<boolean>();
  pendingTasksWidgetReadySubject = new Subject<boolean>();
  consultedTasksWidgetReadySubject = new Subject<boolean>();
  formReadySubject = new Subject<boolean>();
  reloadQuestionViewSubject = new Subject<boolean>();

  constructor(

  ) {

  }

  reloadConsultedTasks() {
    this.consultedTasksSubject.next();
  }

  dismissPopover() {
    this.popoverSubject.next();
  }

  pendingTasksWidgetReady() {
    this.pendingTasksWidgetReadySubject.next();
  }

  consultedTasksWidgetReady() {
    this.consultedTasksWidgetReadySubject.next();
  }

  formReady() {
    this.formReadySubject.next();
  }

  reloadQuestionView() {
    this.reloadQuestionViewSubject.next();
  }

  getConsultedTasksObservable(): Observable<boolean> {
    return this.consultedTasksSubject.asObservable();
  }

  getPopoverObservable(): Observable<boolean> {
    return this.popoverSubject.asObservable();
  }

  getPendingTasksWidgetReadyObservable(): Observable<boolean> {
    return this.pendingTasksWidgetReadySubject.asObservable();
  }

  getConsultedTasksWidgetReadyObservable(): Observable<boolean> {
    return this.consultedTasksWidgetReadySubject.asObservable();
  }

  getFormReadyObservable(): Observable<boolean> {
    return this.formReadySubject.asObservable();
  }

  getReloadQuestionViewObservable(): Observable<boolean> {
    return this.reloadQuestionViewSubject.asObservable();
  }

}
