import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class PlatformService {
  private platforms: any = {
    desktop: 'desktop',
    tablet: 'tablet',
    mobile: 'mobile'
  };
  constructor(private platform: Platform) {}

  getPlatformForDisplay(): 'desktop' | 'app' | 'tablet' | 'mobile' {
    return this.onApp() ? 'app' : this.onTablet() ? 'tablet' : this.onMobile() ? 'mobile' : 'desktop';
  }

  onDesktop(): boolean {
    return this.platform.is(this.platforms.desktop);
  }

  onTablet(): boolean {
    return this.platform.is(this.platforms.tablet) && !this.platform.is(this.platforms.desktop);
  }

  onMobile(): boolean {
    return this.platform.is(this.platforms.mobile) && !this.platform.is(this.platforms.tablet);
  }

  onApp(): boolean {
    return !(this.platform.is('desktop') || this.platform.is('mobileweb'));
  }

  onCordova(): boolean {
    return this.platform.is('cordova') && this.onApp();
  }

  getPlatform(): string {
    return this.onMobile() ? this.platforms.mobile : this.onTablet() ? this.platforms.tablet : this.platforms.desktop;
  }

  onIos(): boolean {
    return this.platform.is('ios');
  }

  onAndroid(): boolean {
    return this.platform.is('android');
  }

  onIE(): boolean {
    return navigator.userAgent.indexOf('Trident') >= 0;
  }

  bindTo(target: any): void {
    target.onDesktop = this.onDesktop();
    target.onTablet = this.onTablet();
    target.onMobile = this.onMobile();
    target.onApp = this.onApp();
    target.platform = this.getPlatform();
  }
}
