import { ElementRef, Injectable } from '@angular/core';
import * as jQuery from 'jquery';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  constructor() { }

  setupCentering(elementRef: ElementRef): void {
    window.addEventListener('resize', () => {
      this.center(elementRef);
    });
    this.center(elementRef);
  }

  center(elementRef: ElementRef): void {
    setTimeout(() => {
      const modalElement: any = jQuery(elementRef.nativeElement);
      const modalWrapper: any = modalElement ? modalElement.parents('.modal-wrapper') : undefined;
      if (modalWrapper && modalWrapper.length) {
        /* modal */
        const modalHeight: number = modalWrapper.outerHeight();
        const modalWidth: number = modalWrapper.outerWidth();

        /* viewport */
        const viewportHeight: number = jQuery(window).height();
        const viewportWidth: number = jQuery(window).width();

        /* set height */
        if (modalHeight < viewportHeight) {
          modalWrapper.css({
            top: `calc(50vh - ${modalHeight / 2}px)`
          });

        } else {
          modalWrapper.css({
            top: `0`
          });
        }

        /* set width */
        if (modalWidth < viewportWidth) {
          modalWrapper.css({
            left: `calc(50vw - ${modalWidth / 2}px)`
          });

        } else {
          modalWrapper.css({
            left: `0`
          });
        }
      }
    });
  }
}
