import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { NavController } from '@ionic/angular';

import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(
    private userService: UserService,
    private navController: NavController,
  ) {

  }

  checkAccess(path: string, resolve: Function) {
    if (this.userService.isLoggedIn()) {
      if (path === 'login') {
        resolve(false);
        this.navController.navigateRoot('home').then();
      } else {
        resolve(true);
      }
    } else {
      if (path !== 'login') {
        resolve(false);
        this.navController.navigateRoot('login').then();
      } else {
        resolve(true);
      }
    }
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {
    return new Promise((resolve) => {
        this.checkAccess(route.url.join(''), resolve);
    });
  }
}
