import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor() { }

  getDatePlusDays(date: Date, days: number): Date {
    return new Date(date.setDate(date.getDate() + days));
  }
}
