import { Component, OnInit, OnDestroy } from '@angular/core';

import { NavController } from '@ionic/angular';

import { TranslationService } from 'src/app/services/translation/translation.service';
import { ObservablesService } from 'src/app/services/observables/observables.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-popover-header-profile',
  templateUrl: './popover-header-profile.component.html',
  styleUrls: ['./popover-header-profile.component.scss'],
})
export class PopoverHeaderProfileComponent implements OnInit {

  ACTIONS = {
    LOGOUT: 1
  };
  options: any[] = [];

  constructor(
    private navController: NavController,
    private translationService: TranslationService,
    private observables: ObservablesService,
    private userService: UserService
  ) {

  }

  ngOnInit() {
    this.loadOptions();
  }

  loadOptions() {
    this.options.push({text: this.translationService.getValueForKey('generic.logout'), action: this.ACTIONS.LOGOUT});
  }

  onOptionClicked(option: any) {
    if (option.action === this.ACTIONS.LOGOUT) {
      this.observables.dismissPopover();
      this.userService.getLogout();
      this.navController.navigateRoot('login').then();
    }
  }
}
