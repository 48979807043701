import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  private translations: any = {};
  constructor() { }

  setTranslations(translations: any): void {
    this.translations = translations;
  }

  bind(target: any): void {
    target.translations = this.translations;
    const translationService: TranslationService = this;
    target.translate = (key: string): string => {
      return translationService.get(target.translations, key);
    };

    target.translationsReady = true;
  }

  get(translations: any, key: string): string {
    return translations ? (translations[key] || key) : key;
  }

  getValueForKey(key: string): string {
    return this.get(this.translations, key);
  }

  getMonthTagByDate(date: Date): string {
    return this.getValueForKey(`generic.dateMonth.${date.getMonth()}`);
  }
}
