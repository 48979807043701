import { ComponentConfiguration } from 'src/app/models/component-configuration';
import { menuConfiguration } from 'src/app/models/default-component-configuration';

export class ConfigurationService {
  public static config: ComponentConfiguration;
  static configure(componentsConfig: ComponentConfiguration): void {
    ConfigurationService.config = componentsConfig;
  }

  static getMenuConfiguration(): any[] {
    return menuConfiguration;
  }
}
